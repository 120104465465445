<script>
import { onMounted, defineComponent, ref } from "vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"
import { useRoute, useRouter } from "vue-router"
import axios from "axios"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      if (route.query.token != undefined) {
        const token = route.query.token.toString()

        axios({
          baseURL: "https://auth.otonomi.no",
          url: "/validate",
          data: {},
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((resp) => {
            console.log(resp.status)
            setTimeout(() => {
              router.push("/")
            }, 5000)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        setTimeout(() => {
          router.push("/")
        }, 2000)
      }
    })
    return {}
  },
})
</script>

<template>
  <main>
    <section class="page">
      <section class="pricing">
        <div class="content">
          <Icon>mark_email_read</Icon>
          <h1>Verified!</h1>
          <p class="light-text">You have successfully verified your account</p>
          <Button
            bgColor="var(--color-dark-green)"
            txtColor="#ffffff"
            shadow="var(--shadow-green)"
            ><Icon>login</Icon> Go to dashboard</Button
          >
        </div>
      </section>
    </section>
  </main>
  <Footer></Footer>
</template>

<style lang="scss" scoped>
@import "../css/home.scss";

.white-box {
  background: white;
  padding: 30px;
  text-align: left;
  border-radius: 24px;
  box-shadow: var(--shadow-light);
}
.light-text {
  color: var(--color-text-light);
}

.pricing-container {
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  width: 1300px;

  .PricingPlanBox {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 10px;

    .top-half {
      border-bottom: 1px solid var(--color-light-blue-grey);
      padding-bottom: 10px;

      .title {
        font-size: 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
        // color: var(--color-dark-green);
      }
      .size {
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1.5rem;
        color: var(--color-dark-green);
      }
      .price {
        font-size: 3rem;
        letter-spacing: -0.2rem;
        font-weight: 900;
      }
      .description {
        font-size: 1rem;
        color: var(--color-text-light);
      }
    }
    .bottom-half {
      padding-top: 35px;

      .features {
        margin: 0;
        padding: 0 0 0 1.3rem;
      }
      .item {
        display: flex;
        margin-bottom: 10px;
      }
      .feature {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--color-text-light);
        margin: auto 10px;
      }
      .number {
        font-size: 1.1rem;
        font-weight: 700;
        position: relative;

        &::before {
          content: "+";
          position: absolute;
          left: -1.3rem;
          color: var(--color-dark-green);
        }
      }
    }
  }

  .highlight {
    background: var(--color-bg-dark);
    color: white;
    border: 5px solid white;
    box-sizing: border-box;
    box-shadow: var(--shadow-dark);
  }
}
</style>
